<template>
  <div>正在处理...</div>
</template>

<script>
export default {
  name: 'index',
  components: {},
  data() {
    return {}
  },
  methods: {
    init() {
      this.$axios.get('/Pay/CallBack', {params: this.$route.query}).then(() => {
        this.$router.push({path: '/order/details', query: {id: this.$route.query.out_trade_no}})
      })
    }
  },
  created() {
    this.init()
  }
}
</script>
